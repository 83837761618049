<template>
  <v-container fluid>
    <v-row class="pb-4">
      <v-col class="col-4">
        <h1>Create a new purchase order</h1>
      </v-col>
    </v-row>
    <div v-if="!supplier.name">
      <v-row>
        <v-col class="col-6">
          <v-text-field
          clearable
          @keyup="lookUpSuppliersDebounced"
          label="Search for a supplier"
          v-model="searchTerm"></v-text-field>
        </v-col>
      </v-row>
      <v-row dense v-if="foundSuppliers.length > 0">
        <v-col class="col-10">
          <v-list>
            <v-list-item
              @click="populateSupplier(index)"
              v-for="(supplier, index) in foundSuppliers" :key="`s_${index}`">
                {{ supplier.name }} {{ supplier.address }}
            </v-list-item>
          </v-list>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import axios from '../../axios';

export default {
  name: 'CreatePo',
  data() {
    return {
      foundSuppliers: [],
      message: '',
      searchTerm: '',
      showMessage: false,
      supplier: {},
    };
  },
  computed: {
    token() {
      return this.$store.state.token;
    },
    userid() {
      return this.$store.state.userid;
    },
  },
  methods: {
    lookUpSuppliersDebounced() {
      clearTimeout(this._timerId);
      this._timerId = setTimeout(() => {
        this.lookUpSuppliers();
      }, 250);
    },
    lookUpSuppliers() {
      if (this.searchTerm) {
        if (this.searchTerm.length > 1) {
          const postData = { searchTerm: this.searchTerm };
          axios.post(`/suppliers/lookup.json?token=${this.token}`, postData)
          .then((response) => {
            if (response.data.suppliers === false) {
              this.message = response.data.message;
              this.showMessage = true;
            } else {
              this.foundSuppliers = response.data.suppliers;
            }
          });
        }
      }
    },
    populateSupplier(index) {
      const postData = {};
      postData.supplier = this.foundSuppliers[index];
      postData.userid = this.userid;
      axios.post(`/purchaseOrders/create.json?token=${this.token}`, postData)
        .then((response) => { 
          const po = response.data.purchaseOrder;
          this.$router.push(`/purchasing/purchase-orders-view/${po.id}`);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    checkSupplierId() {
      if (this.$route.params.id) {
        const postData = {};
        postData.supplierId = this.$route.params.id;
        postData.userid = this.userid;
        axios.post(`/purchaseOrders/create.json?token=${this.token}`, postData)
        .then((response) => { 
          const po = response.data.purchaseOrder;
          this.$router.push(`/purchasing/purchase-orders-view/${po.id}`);
        })
        .catch((error) => {
          console.log(error);
        });
      }
    },
  },
  mounted() {
    this.checkSupplierId();
  },
}
</script>
